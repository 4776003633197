<script>
// let previousRoute;
export default {
  created() {
    console.log("created");
    let redirect = this.$route.query.next || "/";
    this.$store.dispatch("session/logout");
    // this.$router.replace({ name: "finder" });
    // console.log(this.$router);
    // console.log("in logout...", this.$route);
    this.$router.replace(redirect);
  },
};
</script>

<template>
  <b-loading :active="true" />
</template>
